@font-face {
    font-family: "AktivGrotesk";
    src: url(../../assets/fonts/AktivGrotesk-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: "AktivGrotesk";
    src: url(../../assets/fonts/AktivGrotesk-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: "AktivGrotesk";
    src: url(../../assets/fonts/AktivGrotesk-Bold.ttf);
    font-weight: 600;
}
